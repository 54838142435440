import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';

const PageSection = (props: {
    type: 'primary' | 'secondary';
    children: React.ReactNode;
}) => {
    const { type, children } = props;

    const bgColor = type === 'primary' ? 'bg-[#000000]' : 'bg-[#ede0d4]';

    return (
        <section className={`h-screen snap-start ${bgColor} p-24`}>
            {children}
        </section>
    );
};

const Project = () => {
    return (
        <div className="mb-4">
            <Link to="/projects/crimson-tree-software">
                <h6 className="text-1xl font-bold uppercase transform-color ease-in duration-300 hover:text-sky-700">
                    Crimson Tree Software
                </h6>
            </Link>
            <p>
                Building data driven applications for the accounting industry.
            </p>
        </div>
    );
};

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            allMarkdownRemark(
                sort: { fields: frontmatter___date, order: DESC }
            ) {
                nodes {
                    frontmatter {
                        date
                        title
                        slug
                    }
                }
            }
        }
    `);

    return (
        <Layout title={data.site.siteMetadata.title}>
            <main className="max-h-screen overflow-y-scroll snap-mandatory snap-y font-mono">
                <PageSection type="secondary">
                    <div className="h-full w-3/4 m-auto grid grid-cols-2 gap-x-2 justify-center items-center">
                        <div className="">
                            <StaticImage
                                className="drop-shadow-lg"
                                src="../static/images/me.jpg"
                                alt="Nathan Hefner profile picture"
                            />
                            <div className="mt-6">
                                <Link
                                    className="mr-6"
                                    to="https://github.com/natehefner">
                                    GitHub -&gt;
                                </Link>
                                <Link
                                    className="mr-6"
                                    to="https://www.linkedin.com/in/nathanhefner/">
                                    LinkedIn -&gt;
                                </Link>
                            </div>
                        </div>
                        <div className="">
                            <h1 className="relative w-full flex-none mb-2 text-3xl font-bold uppercase">
                                Nathan Hefner &#9679; Software Engineer
                            </h1>
                            <p className="mb-1">
                                Now, one day, a man went to work, and on the way
                                he met another man, who, having bought a loaf of
                                Polish bread, was heading back home where he
                                came from.
                            </p>
                            <p className="mb-3">And that's it, more or less.</p>
                            <p>
                                - Danil Kharms, <i>The Meeting</i>
                            </p>
                        </div>
                    </div>
                </PageSection>
            </main>
        </Layout>
    );
};

export default IndexPage;
